import React, { lazy } from "react";
const ClubLogo = lazy(() => import("../../ClubLogo"));
const PlayerImage = lazy(() => import("../../PlayerImg"));


export const Lineup = React.memo(({lineup, selected, setSelected }) => {
  return (
    <div>
        <div className='mb-[20px] flex'>
            <div className='font-anton text-[#fff] text-[30px] md:text-[40px] uppercase flex-grow'>
                TEAM LINEUPS
            </div>
        </div>
        <div className='md:flex text-[#18062f] font-chakra font-normal bg-white'>
            {(() => {
                let lastBall=0;
                return lineup.teams.map((team, index) => {

                    return (
                        <div className='w-full md:w-[50%] bold pb-[30px]'>
                            <div className={`flex-grow h-[34px] bg-[#d2ff00] border-solid border-[#18062f] border-[1px] ${index===0 ? ('md:border-r-[0px]') : ( null )} font-chakra text-[#18062f] text-[14px] pt-[10px] pl-[10px] flex`}>
                                <div className='relative top-[-7px]'><ClubLogo teamName={team.name} height='24px' width='24px' /></div>
                                <div className='pl-[10px]'>{team.name}</div>
                            </div>



                            {team.players.map((player, idx) => {
                                return (
                                    <div className='w-full h-[34px] font-chakra py-[10px] pl-[10px] text-left border-solid border-[#c5c2c8] border-[1px] text-[#18062f] flex'>
                                        <div className='relative top-[-5px]'>            
                                            <PlayerImage teamName={team.name} playerName={player.name} width='24px' height='24px' />
                                        </div>
                                        <div className="pl-[10px]">
                                            {player.name}
                                            {player.iscaptain!==0 ? ( <span className='ml-[5px]'>(c)</span> ) : ( null )}
                                        </div>
                                    </div>
                                );
                            })}




                        </div>
                    )
                });
            })()}
        </div>
    </div>
  );
});
export default Lineup;
